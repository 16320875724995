// TODO: Convert to tailwind?
.scrollTop {
  position: fixed;
  bottom: 25px;
  align-items: center;
  right: 55px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;

  animation: fadeIn 0.75s;
  transition: opacity 0.4s;
  opacity: 0.75;
}

.scrollTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}