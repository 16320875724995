///////////////////////////////////////////////////////
/// Fonts
///////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:900&display=swap');

///////////////////////////////////////////////////////
/// Tailwind
///////////////////////////////////////////////////////
@tailwind base;
@tailwind components;
@tailwind utilities;

///////////////////////////////////////////////////////
/// Colour definitions
///////////////////////////////////////////////////////
:root {
  --accent: #1e817f;
}

///////////////////////////////////////////////////////
/// Utility class
///////////////////////////////////////////////////////
.w\-1440 {
  max-width: 1140px !important;
}

///////////////////////////////////////////////////////
/// Body classes
///////////////////////////////////////////////////////
.body {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  background: #ebedf1 url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%238a24f5' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.body::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ebedf1+0,ebedf1+100&0.85+0,1+99 */
  background: -moz-linear-gradient(top,  rgba(235,237,241,0.9) 0%, rgba(235,237,241,1) 99%, rgba(235,237,241,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(235,237,241,0.9) 0%,rgba(235,237,241,1) 99%,rgba(235,237,241,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(235,237,241,0.9) 0%,rgba(235,237,241,1) 99%,rgba(235,237,241,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9ebedf1', endColorstr='#ebedf1',GradientType=0 ); /* IE6-9 */
  z-index: 0;
}

///////////////////////////////////////////////////////
/// Main class (makes it stay above the background)
///////////////////////////////////////////////////////
main {
  position: relative;
  z-index: 1;
}

///////////////////////////////////////////////////////
/// The rest of the SCSS
///////////////////////////////////////////////////////
.navbar img {
  height: 6rem;
  width: 6rem;
  border-radius: 9999px;
  margin-right: 1rem;
}

.navbar .nav-link {
  text-transform: lowercase;
  color: var(--accent);
  font-size: 1.2rem;
  margin: 0 1rem;
}

h1 {
  font-weight: 900;
  font-size: 4rem;
}

header h2 {
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  color: var(--accent);
}

section {
  padding: 6rem 0;
}

section::before {
  content: '';
  display: block;
  width: 2px;
  height: 64px;
  margin: 0 auto;
  background: var(--accent);
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgb(84, 219, 253) 100%);
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgb(84, 219, 253) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgb(84, 219, 253) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#8a24f5',GradientType=0 );
}

section > h2 {
  margin-top: 3rem;
  font-size: 2.5rem;
  letter-spacing: 0.1em;
  color: var(--accent);
  position: relative;
}

section > h2::before {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 8rem;
  font-weight: 700;
  color: rgba(0,0,0,.2);
  font-family: 'Muli', sans-serif;
}

.about img {
  width: 8rem;
  height: 8rem;
  border-radius: 9999px;
}

///////////////////////////////////////////////////////
/// Section number titles
///////////////////////////////////////////////////////
.about > h2::before {
  content: '01';
}

.skills > h2::before {
  content: '02';
}

.work > h2::before {
  content: '03';
}

.testimonials > h2::before {
  content: '04';
}

///////////////////////////////////////////////////////
/// Spacing beneath the section numbers
///////////////////////////////////////////////////////
.about .content {
  margin-top: 5rem;
}

.work .content {
  margin-top: 2rem;
}

.skills .content {
  margin-top: 4rem;
}

.card {
  background: white;
  border: none;
  border-radius: 0;
}

.about .card {
  position: relative;
}

@media (min-width: 768px) {
  .about .card::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right:16px solid white;
  }
}

.work h3 {
  margin-bottom: 0;
}

.work p {
  margin: 2rem 0;
}

footer a {
  color: var(--accent);
}

.testimonials img {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
}

.quote {
  font-style: italic;
}